import React from 'react'
import { Link, StaticQuery, graphql } from 'gatsby'

import Layout from '../components/layout'
import SEO from '../components/seo'
import Hero from '../components/hero'

export const query = graphql`
  query {
    hero: file(relativePath: { eq: "monkey-404.jpg" }) {
      ...heroImage
    }
  }
`

const NotFoundPage = () => (
  <Layout>
    <SEO title="404: Page Not Found" />
    <StaticQuery
      query={query}
      render={data => (
        <React.Fragment>
          <Hero image={data.hero.childImageSharp.fluid}>
            <div className="max-w-md m-auto text-center">
              <h1 className="font-impact text-5xl ">Shucks</h1>
              <p className="text-3xl text-white text-center  font-serif text-shadow">
                The page you're looking for can't be found.
              </p>
            </div>
          </Hero>
        </React.Fragment>
      )}
    />
  </Layout>
)

export default NotFoundPage
